import React from "react";
import { Link } from "gatsby";
import { Card } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";

const IndexCard = ({ title, text, image, imageAlt, linkTo }) => {
  return (
    <>
      <div className="indexPageCard">
        <Link to={linkTo}>
          <Card className="">
            <Card.Body className="index-card-body">
              <GatsbyImage image={image} alt={imageAlt} />
              <Card.Title className="pt-1">{title}</Card.Title>
            </Card.Body>
          </Card>
        </Link>
      </div>
    </>
  );
};

export default IndexCard;
