import React from "react";
import { Row } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link } from "gatsby";
import Slider from "react-slick";
import Img from "gatsby-image";

import Layout from "../components/layout";
import Seo from "../components/seo";
import IndexCard from "../components/indexCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderItem = ({ name, image, imageAlt, link }) => {
  return (
    <div>
      <Link to={link}>
        <div>
          <Img className="index-page-cat-spinner-img-image" fluid={image} alt={imageAlt} />
          <p className="pt-1 index-page-cat-spinner-text">{name.toUpperCase()}</p>
        </div>
      </Link>
    </div>
  );
};

const IndexPage = ({ data: { images } }) => {
  var imagearr = [];
  var imageFluid = [];
  for (var img of images.edges) {
    imagearr[img.node.name] = img.node.childImageSharp.gatsbyImageData;
    imageFluid[img.node.name] = img.node.childImageSharp.fluid;
  }

  const CategorySlider = ({ slidesToShow }) => {
    const slider_settings = {
      arrows: true,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: slidesToShow,
      //slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 5000,
    };

    return (
      <Slider {...slider_settings}>
        <SliderItem
          name="Beverages"
          image={imageFluid["radioactive-slime-drink2-560x560-circle"]}
          imageAlt="Drink"
          link="/cookbook/beverages"
        />
        <SliderItem
          name="Breakfasts"
          image={imageFluid["scrambled-eggs-560x560-circle"]}
          imageAlt="Scrambled Eggs"
          link="/cookbook/breakfasts"
        />
        <SliderItem
          name="Breakfasts for Dutch Oven"
          image={imageFluid["biscuits-and-gravy2-560x560-circle"]}
          imageAlt="Biscuits and Gravy"
          link="/cookbook/dutch-oven-breakfasts"
        />
        <SliderItem
          name="Breads & Rolls for Dutch Oven"
          image={imageFluid["monkey-bread2-560x560-circle"]}
          imageAlt="Monkey Bread"
          link="/cookbook/dutch-oven-breads"
        />
        <SliderItem
          name="Desserts"
          image={imageFluid["pan-pies-560x560-circle"]}
          imageAlt="Pan Pies"
          link="/cookbook/desserts"
        />
        <SliderItem
          name="Desserts for Dutch Oven"
          image={imageFluid["dutch-oven-fruit-cobbler-560x560-circle"]}
          imageAlt="Dutch Oven Cobler"
          link="/cookbook/dutch-oven-desserts"
        />
        <SliderItem
          name="Main Meals"
          image={imageFluid["foil-dinner2-560x560-circle"]}
          imageAlt="Foil Dinner"
          link="/cookbook/main-meals"
        />
        <SliderItem
          name="Main Meals for Dutch Oven"
          image={imageFluid["pepsi-chicken2-560x560-circle"]}
          imageAlt="Chicken"
          link="/cookbook/dutch-oven-main-meals"
        />
        <SliderItem
          name="Sandwiches"
          image={imageFluid["blt2-560x560-circle"]}
          imageAlt="Sandwich"
          link="/cookbook/sandwiches"
        />
        <SliderItem
          name="Side Dishes"
          image={imageFluid["corn-roasted2-560x560-circle"]}
          imageAlt="Roasted Corn"
          link="/cookbook/side-dishes"
        />
        <SliderItem
          name="Snacks"
          image={imageFluid["ants-on-a-log2-560x560-circle"]}
          imageAlt="Celery with Raisins"
          link="/cookbook/snacks"
        />
        <SliderItem
          name="Soups and Stews"
          image={imageFluid["minestrone2-560x560-circle"]}
          imageAlt="Soup"
          link="/cookbook/#soups-and-stews"
        />
      </Slider>
    );
  };

  //<StaticImage src="../images/index-page/campfire1280x720.jpg" alt="Campfire cooking" loading="eager"/>
  //<StaticImage src="../images/index-page/cookbook.jpg" alt="Cookbook" loading="eager"/>
  //<StaticImage src="../images/index-page/ilovecooking1280x720.jpg" alt="Kitchen gadget graphic" loading="eager"/>
  // <GatsbyImage image={imagearr["cookbook"]} alt="Kitchen gadget graphic" />
  return (
    <Layout pageInfo={{ pageName: "index" }}>
      <Seo title="Home" keywords={[`recipes`]} description="Great website with great recipes!" url="" />

      <Row className="text-center">
        <div className="g-0" id="index-page-cookbook-banner">
          <div className="d-flex justify-content-center">
            <div id="index-page-campfire" className="d-flex align-items-center ps-2">
              <GatsbyImage image={imagearr["campfire-marshmallows-circle500"]} alt="Campfire" />
            </div>
            <div className="d-flex justify-content-evenly" id="index-page-cookbook-banner2">
              <div className="d-flex flex-wrap flex-md-nowrap justify-content-md-around">
                <div className="d-flex align-items-center justify-content-center flex-grow-1 flex-md-shrink-1">
                  <div id="index-page-cookbook-title">THE FREE ONLINE CAMPING COOKBOOK</div>
                </div>
                <div className="d-flex align-items-center justify-content-center flex-grow-1">
                  <div>
                    <Link to="/cookbook">
                      <div id="index-page-cookbook-link">CAMPING&nbsp;COOKBOOK</div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <Row
        className="justify-content-center d-flex flex-wrap pt-4 pb-3 ps-4 pe-4 text-center"
        id="index-page-browse-title"
      >
        BROWSE CAMPING RECIPES BY CATEGORY
      </Row>
      <Row className="gy-4 justify-content-center d-flex flex-wrap ps-4 pe-4 text-center" id="index-page-cat-spinner">
        <div id="index-page-cat-spinner-lg" className="pb-3">
          <CategorySlider slidesToShow={8} />
        </div>
        <div id="index-page-cat-spinner-sm" className="pb-3">
          <CategorySlider slidesToShow={4} />
        </div>
        <div id="index-page-cat-spinner-xs" className="pb-3">
          <CategorySlider slidesToShow={3} />
        </div>
      </Row>

      <Row className="text-center pt-2">
        <div className="g-0 ps-2 pe-2 mb-3" id="index-page-dot-banner">
          <div className="d-flex justify-content-center">
            <div id="index-page-dot" className="d-flex align-items-center">
              <GatsbyImage image={imagearr["dutch-oven-mountain"]} alt="Dutch Oven" />
            </div>
            <div className="d-flex justify-content-evenly p-2" id="index-page-dot2">
              <div className="d-flex flex-wrap flex-md-nowrap justify-content-md-around">
                <div className="d-flex align-items-center justify-content-center flex-grow-1 flex-md-shrink-1">
                  <div id="index-page-dot-title">
                    Dutch Oven Cooking
                    <br />
                    is Fun and Easy
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center flex-grow-1 p-2">
                  <div>
                    <Link to="/dutch-oven-cooking-tips">
                      <div id="index-page-dot-link">DUTCH&nbsp;OVEN&nbsp;TIPS</div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <Row
        className="justify-content-center d-flex flex-wrap pt-4 pb-3 ps-4 pe-4 text-center"
        id="index-page-browse-title"
      >
        FEATURED RECIPES
      </Row>

      <Row>
        <div className="d-flex justify-content-center">
          <div
            id="index-page-recipe-container"
            className="d-flex text-center justify-content-center flex-wrap flex-shrink-1"
          >
            <IndexCard
              title="Dutch Oven Fiesta Omelet"
              text=""
              image={imagearr["fiesta-omelete-560x560"]}
              imageAlt="Egg omelete"
              linkTo="/recipes/view/dutch-oven-fiesta-omelet/"
            />
            <IndexCard
              title="Buttermilk Pancakes"
              text=""
              image={imagearr["pancakes-560x560"]}
              imageAlt="Pancakes"
              linkTo="/recipes/view/buttermilk-pancakes/"
            />
            <IndexCard
              title="Pan Pies"
              text=""
              image={imagearr["pan-pies-560x560"]}
              imageAlt="Fried pan pies"
              linkTo="/recipes/view/pan-pies/"
            />
            <IndexCard
              title="Breakfast Burrito"
              text=""
              image={imagearr["breakfast-burrito-560x560"]}
              imageAlt="Breakfast Burrito"
              linkTo="/recipes/view/breakfast-burrito/"
            />
            <IndexCard
              title="Fiesta Mac and Cheese"
              text=""
              image={imagearr["fiesta-mac-and-cheese-560x560"]}
              imageAlt="Fiesta Mac and Cheese"
              linkTo="/recipes/view/fiesta-mac-and-cheese/"
            />
            <IndexCard
              title="Dogs in a Straight Jacket"
              text=""
              image={imagearr["hot-dog-bacon-560x560"]}
              imageAlt="Dogs in a Straight Jacket"
              linkTo="/recipes/view/dogs-in-a-straight-jacket/"
            />
            <IndexCard
              title="Pita Pizzas"
              text=""
              image={imagearr["pita-pocket-560x560"]}
              imageAlt="Pita Pizzas"
              linkTo="/recipes/view/pita-pizzas/"
            />
            <IndexCard
              title="Smores"
              text=""
              image={imagearr["smores-560x560"]}
              imageAlt="Smores"
              linkTo="/recipes/view/smores/"
            />
          </div>
        </div>
      </Row>
    </Layout>
  );
};

export default IndexPage;

export const indexPageQuery = graphql`
  query indexPageImages {
    images: allFile(filter: { sourceInstanceName: { eq: "index-page-images" } }) {
      edges {
        node {
          dir
          ext
          sourceInstanceName
          name
          childImageSharp {
            gatsbyImageData
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
